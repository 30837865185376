<div class="navbar-container">
  <div class="left-side">
    <img src="assets/images/logo-dark.png" alt="scrub-valley-logo" height="60px" />
  </div>
  <div class="right-side">
    <!-- <div class="navbar-icon-container">
      <mat-icon fontIcon="notifications_none" matBadge="0" matBadgeSize="large"></mat-icon>
    </div> -->
      <div class="navbar-icon-container" [matMenuTriggerFor]="menu">
        <img *ngIf="hasProfilePic() else noProfileImage" [src]="getProfilePic()" width="48px" />
        <ng-template #noProfileImage>
          <mat-icon fontIcon="account_circle"></mat-icon>
        </ng-template>
        <p style="margin: 0">{{getUserName()}}</p>
        <mat-icon fontIcon="keyboard_arrow_down"></mat-icon>
      </div>
  </div>
</div>

<mat-menu #menu="matMenu" class="ct-menu">
  <div class="menu-tittle" translate>{{accountType}}</div>
  @if(canShowDropDown() && accountType !== 'Admin'){
    <button mat-menu-item (click)="onSelectingTab(1)">
      <mat-icon>account_circle</mat-icon>
      <span translate>My Account</span>
    </button>
    <button *ngIf="accountType !== 'Employer'" mat-menu-item (click)="onSelectingTab(2)">
      <mat-icon>file_copy</mat-icon>
      <span translate>My Applications</span>
    </button>
    <button *ngIf="accountType !== 'Employer'" mat-menu-item (click)="onSelectingTab(3)">
      <mat-icon>save</mat-icon>
      <span translate>Saved Applications</span>
    </button>
    <!-- <button *ngIf="accountType === 'Employer'" mat-menu-item (click)="onSelectingTab(5)">
      <mat-icon>save</mat-icon>
      <span>Job Posting Draft</span>
    </button> -->
    <button mat-menu-item (click)="onSelectingTab(4)">
      <mat-icon>calendar_today</mat-icon>
      <span translate>My Calendar</span>
    </button>
    <mat-divider></mat-divider>
  }<button mat-menu-item (click)="onSelectingTab(6)">
    <mat-icon>lock</mat-icon>
    <span translate>Change Password</span>
  </button>
  <button mat-menu-item (click)="onSelectingTab(7)">
    <mat-icon>language</mat-icon>
    <span>{{getLanguage()}} </span>
  </button>
  <button mat-menu-item (click)="onLogout()">
    <mat-icon>logout</mat-icon>
    <span translate>Logout</span>
  </button>
</mat-menu>